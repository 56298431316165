<template>
  <div>
    <Header></Header>
    <div class="topView">
      <a-carousel>
        <div v-for="(i,index) in banner" :key="index" :style="{backgroundColor:i.bgColor}">
          <img :src="i.imgUrl" class="topImgs" style="margin: 0 auto;">
        </div>
      </a-carousel>
    </div>
    <div class="cateMain">
      <div class="cateMainItem" v-for="(r,index) in categories" :key="index" @click="goCate(r)">
        <img :src="r.imgUrl" style="width: 100%;height: auto;" v-if="r.imgUrl">
        <div class="cateMainNull" v-else>{{ r.name }}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {get} from "@/utils/request";

let defalutImg = require("@/assets/index/pc-b.jpg");
export default {
  name: "CateIndex",
  components: {},
  data() {
    return {
      banner: [],
      categories: [],
      defaultCateId: '',
      t: ''
    }
  },
  watch: {
    "$route.query": {
      handler() {
        let datas = this.$route.query;
        // console.log('watch：', datas)
        if (datas.type === 'A') {
          if (datas.cid && datas.t) {
            this.defaultCateId = this.$route.query.cid
            this.t = datas.t
            this.categories = []
            this.getTopImgs()
            this.pageType = 'catePage'
          } else {
            this.$router.replace('/errorPage')
          }
        }
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    this.$nextTick(() => {
      const bodyStyle = document.body.style; // 获取body节点样式
      bodyStyle.background = "#ffffff";
    });
  },
  methods: {
    async getTopImgs() {
      await get("/api/wiki/apiQueryWikiCarousel").then(res => {
        if (res.data && res.data.length > 0) {
          this.banner = res.data
        } else {
          this.banner = [{
            imgUrl: defalutImg
          }];
        }
        this.getCategories()
      })
    },
    async getCategories() {
      let res = await get("/api/wiki/getAllWikiCategory", {
        categoryId: this.defaultCateId,
      });
      if (res.data && res.data.length > 0) {
        this.categories = res.data.slice(0, 4);
      }
    },
    goCate(r) {
      this.$router.push({
        path: "/Home",
        query: {
          t: this.t,
          type: 'A',
          cid: r.id,
          cname: r.name
        }
      })
    }
  }
}
</script>

<style scoped>

.cateMain {
  box-sizing: border-box;
}

.topView {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 580px;
  overflow: hidden;
//background: #E3F1FA;
}

.topViewItem {
  width: 100%;
  display: flex;
  align-items: center;
}

.el-carousel__container {
  height: inherit;
}

.cateMainItem {
  width: 25%;
  float: left;
  box-sizing: border-box;
  cursor: pointer;
  padding: 1px;
}

.cateMainNull {
  height: 315px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f1f1f1;
}

@media only screen and (max-width: 1000px) {
  .cateMainItem {
    width: 50%;
  }

  .cateMainNull {
    height: 125px;
  }

  .ant-carousel >>> .slick-slide {
    height: 225px;
  }

  .topImgs {
    width: 100%;
  }
}

@media only screen and (min-width: 1000px) {
  .topImgs {
    width: 51%;
  }
}
</style>
